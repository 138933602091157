import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from '@reach/router';
import Loader from "react-loader-spinner";

import { captureEvent } from 'event-service/modules/capture'

import { FinanceCalculatorContext } from '../financing/storage/CalculatorContext';
import useRenderizable from '../hooks/useVehicleContextValidator';
import useDataLayer from '../../../hooks/useDataLayer';
import mfc from '../modules/modalFormClient';

import "../../contact/ContactForm.scss"
import grid from "../../../scss/flexboxgrid.module.scss"
import view from "./form.module.scss"

import {localStorage, getStorageItem, setStorageItem, removeStorageItem} from '../../localStorage/localStorage.js'

function AvailabilityForm(props) {
  const [context] = useContext(FinanceCalculatorContext)
  const { finalPrice, vehicleInfo } = props.contextualized ? context : props
  const { site_id, leadURL, disclaimerContact, gtmId } = props

  const selectedDealer = getStorageItem('selectedDealer')
  const closestDealers = localStorage.getItem("localDistanceData"+site_id) != 'null' ? JSON.parse(localStorage.getItem("localDistanceData"+site_id)): []

  const dealers = (closestDealers != null && closestDealers.hasOwnProperty("closest_dealers") && closestDealers.closest_dealers.length > 0) ? closestDealers.closest_dealers : props.dealers.dealerRef.sort((a, b) => {
    var textA = a.dealerName.toUpperCase();
    var textB = b.dealerName.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }); 



  const pushToDataLayer = useDataLayer()

  const requiredFields = ["VIN", "StockNumber", "Year", "Make", "Model", "Trim"]
  const renderizable = useRenderizable({ finalPrice, vehicleInfo }, requiredFields)
  const isRenderizable = props.contextualized ? renderizable : true

  const filterLabels = () => Object.fromEntries(
    requiredFields.map(element => [element, vehicleInfo[element]])
  )

  const [fields, setFields] = useState({
    firstName: null, lastName: null, email: null, phoneNumber: null, comments: null, dealerName: getStorageItem('selectedDealer'),
    siteId: site_id, pageSource: props.pageSource, pageURL: props.pageURL
  })

  const location = useLocation()
  useEffect(() => {
    if(props.autofillable){
      const userForm = {}
      const params = new URLSearchParams(location.search)
      for (const [key, value] of params) {
        fields.hasOwnProperty(key) && (userForm[key] = value);
      }
      setFields({ ...fields, ...userForm})
    } 
  }, [])

  const [error, setError] = useState([])

  const [statusMsg, setStatusMsg] = useState("")
  const [loading, isLoading] = useState(false)
  const [success, isSuccessful] = useState(false)
  const [engagement, setEngagement] = useState(false)

  useEffect(() => {
    if (engagement) {
      captureEvent({
        event: 'asc_form_engagement',
        comm_type: 'form',
        comm_status: 'start',
        element_text: engagement?.innerText,
        department: 'sales',
        form_name: 'Check Availability',
        form_type: 'sales',
        item_id:  vehicleInfo.VIN,
        item_number: `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model} ${vehicleInfo.Trim}`,
        item_price: `${finalPrice}`,
        item_condition: `${vehicleInfo.IsNew ? 'New' : 'Used'}`,
        item_year: vehicleInfo.Year,
        item_make: vehicleInfo.Make,
        item_model: vehicleInfo.Model,
        item_variant: vehicleInfo.Trim,
        item_color: vehicleInfo.ExteriorColor,
        item_type:'vehicle',
        item_category: vehicleInfo.BodyType,
        item_fuel_type: vehicleInfo.EngineFuelType,
        item_inventory_date: vehicleInfo.InStockDate,
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
    }
  }, [engagement])

  const handleTextInput = (name, value) => {
    const isErrored = error.indexOf(name)
    if(isErrored > -1){
      const _error = [...error]
      _error.splice(isErrored, 1)
      setError(_error)
    }

    setStatusMsg("")
    setFields({ ...fields, ...{ [name]: value } })
  }

  const phoneMask = (e) => {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }

  const submitForm = async (e) => {
    e.preventDefault()

    const missing = mfc.validateForm(fields, ['firstName', 'lastName', 'email', 'phoneNumber'])
    if (missing.length > 0) {
      setError([...missing])
      return setStatusMsg("One or more fields are missing!")
    }

    isLoading(true)

    const body = { ...fields, vehicle: filterLabels() }
    const result = await mfc.sendForm(leadURL, body)
    if (result.hasOwnProperty("error")) {
      setStatusMsg("Uh oh! Something went wrong, please try again!")
    } else {
      captureEvent({
        event: 'asc_form_submission',
        comm_type: 'form',
        comm_outcome: 'crm_update',
        submission_id: `${result.result.data.LeadID.id}`,
        element_text: 'submit',
        department: 'sales',
        form_name: 'Check Availability',
        form_type: 'sales',
        item_id:  vehicleInfo.VIN,
        item_number: `${vehicleInfo.Year} ${vehicleInfo.Make} ${vehicleInfo.Model} ${vehicleInfo.Trim}`,
        item_price: `${finalPrice}`,
        item_condition: `${vehicleInfo.IsNew ? 'New' : 'Used'}`,
        item_year: vehicleInfo.Year,
        item_make: vehicleInfo.Make,
        item_model: vehicleInfo.Model,
        item_variant: vehicleInfo.Trim,
        item_color: vehicleInfo.ExteriorColor,
        item_type:'vehicle',
        item_category: vehicleInfo.BodyType,
        item_fuel_type: vehicleInfo.FuelType,
        item_inventory_date: vehicleInfo.InStockDate,
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      pushToDataLayer("form-submitted", result)
      pushToDataLayer("contact", result)
      setStatusMsg("Thank you! Someone will reach back out to you shortly.")
      isSuccessful(true)
    }
    
    isLoading(false)

  }

  const updateDealerDropdown = (value) => {
    setStorageItem('selectedDealer', value)
    fields.dealerName = value;
  }

  return (
    isRenderizable && (
      <div className={`contact-formCont ${view["contact-form"]}`}>
        <div className={`contact-inputCont ${grid["col-sm-12"]} select`} onClick={() => setEngagement({innerText: "Dealer"})}>
          <label htmlFor="dealerName">Dealer</label>
          <select id="dealerName" name="dealerName" onChange={(e) => {updateDealerDropdown(e.target.value);}}>
              {
                props.pageLoc == 'VDP' ? 
                <option value={vehicleInfo.DealerName} selected={true}>{vehicleInfo.DealerName}</option> :
                dealers.map((element, i) => { 
                  let distance = element.hasOwnProperty('distances') ? ' (' + element.distances.distance.text + ')' : '';
                  return(
                  <option key={i} value={element.dealerName} selected={element.dealerName === selectedDealer}>{element.dealerName + distance}</option>
                  )
                })
              }
          </select>
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "First Name"})}>
          <label htmlFor="firstName">First Name *</label>
          <input className="contact-input" id="firstName" name="firstName"
            value={fields.firstName} placeholder=""
            style={{ border: error.indexOf("firstName") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Last Name"})}>
          <label htmlFor="lastName">Last Name *</label>
          <input className="contact-input" id="lastName" name="lastName" 
            value={fields.lastName} placeholder=""
            style={{ border: error.indexOf("lastName") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont contact-phone ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Phone"})}>
          <label htmlFor="phoneNumber">Phone *</label>
          <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            id="phoneNumber" name="phoneNumber" 
            value={fields.phoneNumber} placeholder="(555) 555-5555"
            style={{ border: error.indexOf("phoneNumber") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)}
            onInput={phoneMask}
          />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} onClick={() => setEngagement({innerText: "Email"})}>
          <label htmlFor="email">Email *</label>
          <input className="contact-input" type="email" id="email" name="email"
            value={fields.email} placeholder=""
            style={{ border: error.indexOf("email") > -1 && "1px solid red"}}
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className="contact-inputCont contact-comment" onClick={() => setEngagement({innerText: "Comment"})}>
          <label htmlFor="comments">Comment</label>
          <textarea className="contact-input" id="comments" name="comments"
            value={fields.comments} placeholder=""
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className="contact-inputCont contact-border">
          <div className="contact-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimerContact }}></div>
          {!success && !loading ?
            <button className="contact-button" onClick={submitForm}>
              SUBMIT
            </button>  :
            <div className={success ? "circle-loader-contact load-complete-contact" : "circle-loader-contact"}>
              <div className={success ? "checkmark-contact draw" : ""}></div>
            </div>
          }
        </div>

        <div className={`contact-response ${statusMsg.length && "contact-failure"}`}>
          {statusMsg}
        </div>
      </div>
    )
  )
}

AvailabilityForm.defaultProps = {
  leadURL: "",
  disclaimerContact: "<p></p>",
  finalPrice: 0,
  vehicleInfo: {}
}

export default AvailabilityForm